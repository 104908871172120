<template>
  <div class="white black--text">
    <v-row>
      <v-col
        cols="12"
        offset-md="2"
        md="8"
        offset-lg="3"
        lg="6"
        offset-xl="3"
        xl="6"
                class="mt-12"
      >
        <div id="howToDescription">
          <heading1Section
            color="black"
            :dense="true"
            :text="$t('lp_h1_dashboarding')"
          />
          <heading2Section
            color="black"
            :dense="true"
            :text="$t('lp_h2_dashboarding')"
            style="margin-bottom: 2rem"
          />

          <quote class="quote" :text="$t('lp_quote_dashboarding')" />

          <paragraph class="paragraph" :text="$t('lp_p_dashboarding_1')" />

          <div class="text-center">
            <img
              data-aos="fade-right"
              draggable="false"
              :src="
                require('@/assets/graphics/landing/pictures/howto/dashboard/dashboard_01.png')
              "
              style="max-height: 400px; height: 30vh"

              alt="safety events compose plant safety dashboard"
            />
              <!-- style="max-width: 80vw;" -->

          </div>

          <paragraph class="paragraph" :text="$t('lp_p_dashboarding_2')" />
          <paragraph class="paragraph" :text="$t('lp_p_dashboarding_3')" />
        </div>
      </v-col>
    </v-row>
    <div class="text-center" style="">
      <!-- <div class="d-flex justify-center app_preview_container"> -->
      <div class="d-flex justify-center app_preview_container">
        <appOnTv
          functionality="areas"
          image_size="large"
          :images_list="image_list_app_screenshots_monitor"
        />
      </div>
    </div>
    <seeAlso v-if="!wait_seeAlsoBtns" :back_section="back_section" />
    <btnBackHome :back_section="back_section" />
    <!-- <pageFooter height="120px" /> -->
  </div>
</template>

<script>
import heading1Section from "@/components/landings/heading1Section.vue";
import heading2Section from "@/components/landings/heading2Section.vue";
import sectionDescription from "@/components/landings/sectionDescription.vue";
import paragraph from "@/components/landings/paragraph.vue";
import seeAlso from "@/components/landings/blocks/seeAlso.vue";
import btnBackHome from "@/components/landings/btnBackHome.vue";
import cardAsButton from "@/components/landings/cardAsButton.vue";
import appOnTv from "@/components/landings/appOnTv.vue";
import "@/styles/landing.css";
import AOS from "aos";
import "aos/dist/aos.css";
import quote from "@/components/landings/quote.vue";
import pageFooter from "@/components/landings/blocks/pageFooter.vue";

export default {
  metaInfo: {
    title: "Mostra lo stato della sicurezza",
    meta: [
      {
        name: "description",
        content:
          "Dai visibilità agli eventi dimostrando attenzione alla sicurezza e al miglioramento continuo."
      },
    ],
  },

  props: ["from_section"],
  components: {
    pageFooter,
    heading1Section,
    heading2Section,
    sectionDescription,
    cardAsButton,
    appOnTv,
    paragraph,
    seeAlso,
    btnBackHome,
    quote,
  },
  data: () => ({
    back_section: null,
    wait_seeAlsoBtns: true,
  }),
  methods: {
    getTranslations(key) {
      return this.$t(key);
    },
  },
  mounted() {
    AOS.init();
    if (this.$route.params.from_section) {
      this.back_section = this.$route.params.from_section;
      console.log("back to:", this.$route.params.from_section);
    }
    setInterval(() => {
      this.wait_seeAlsoBtns = false;
    }, 500);
  },
  created() {},
  computed: {
    image_list_app_screenshots_monitor() {
      return [
        {
          url: require("@/assets/graphics/landing/pictures/howto/dashboard/app_dashboard_01.png"),
          alt: this.$t("lp_img_detail_dashboarding_img_title_01"),
          title: this.$t("lp_img_detail_dashboarding_img_title_01"),
          desc: this.$t("lp_img_detail_dashboarding_img_desc_01"),
        },
        {
          url: require("@/assets/graphics/landing/pictures/howto/dashboard/app_dashboard_02.png"),
          alt: this.$t("lp_img_detail_dashboarding_img_title_02"),
          title: this.$t("lp_img_detail_dashboarding_img_title_02"),
          desc: this.$t("lp_img_detail_dashboarding_img_desc_02"),
        },
        {
          url: require("@/assets/graphics/landing/pictures/howto/dashboard/app_dashboard_03.png"),
          alt: this.$t("lp_img_detail_dashboarding_img_title_03"),
          title: this.$t("lp_img_detail_dashboarding_img_title_03"),
          desc: this.$t("lp_img_detail_dashboarding_img_desc_03"),
        },
        // {
        //   url: require("@/assets/graphics/landing/pictures/howto/event/app_event_03.png"),
        //   alt: this.$t("lp_img_detail_dashboarding_img_title_03"),
        //   title: this.$t("lp_img_detail_dashboarding_img_title_03"),
        //   desc: this.$t("lp_img_detail_dashboarding_img_desc_03"),
        // },
        // {
        //   url: require("@/assets/graphics/landing/pictures/howto/event/app_event_04.png"),
        //   alt: this.$t("lp_img_detail_dashboarding_img_title_04"),
        //   title: this.$t("lp_img_detail_dashboarding_img_title_04"),
        //   desc: this.$t("lp_img_detail_dashboarding_img_desc_04"),
        // },
        // {
        //   url: require("@/assets/graphics/landing/pictures/howto/event/app_event_05.png"),
        //   alt: this.$t("lp_img_detail_dashboarding_img_title_05"),
        //   title: this.$t("lp_img_detail_dashboarding_img_title_05"),
        //   desc: this.$t("lp_img_detail_dashboarding_img_desc_05"),
        // },
      ];
    },
  },
  watch: {},
};
</script>

